/* validated */
.react-datepicker__day--highlighted-custom-1 {
    border-radius: 0.3rem;
    pointer-events: none;
    color: white !important;
    background: rgb(81, 186, 123, 0.8);
}

/* pending */
.react-datepicker__day--highlighted-custom-2 {
    border-radius: 0.3rem;
    pointer-events: none;
    color: white !important;
    background: rgba(255, 173, 1, 1);
}

/* refused */
.react-datepicker__day--highlighted-custom-3 {
    border-radius: 0.3rem;
    pointer-events: none;
    color: white !important;
    background: rgb(186, 50, 32);
}   

/* highlighted */
.react-datepicker__day--highlighted-custom-4 {
    border-radius: 0.3rem;
    pointer-events: none;
    color: white !important;
    background: #5e5f93;
    /* border: 1px dashed #FFAD01; */
}   